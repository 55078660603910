import React from 'react';
import { DEFAULT_IMG, Gift } from '../utils';
import DetailsDescription from './DetailsDescription';
import { isMobile } from 'react-device-detect';

type GiftRowItemProps = {
  gift: Gift,
  key: string,
  currentUser: string,
  sessionUser: string,
}

const GiftRowItem = ({key, gift, currentUser}: GiftRowItemProps) => {
  const {fields, id} = gift;
  const {Archived, description, Name, product_image, product_price, product_price_page} = fields;
  const link = `/${currentUser}/${id}`
  
  return (
    <li key={key} className={`pl-3 flex py-5 border rounded ${Archived && 'bg-gray-300 border border-gray-700'}`}>

      <div className="flex-shrink-0">
        <img src={product_image || DEFAULT_IMG} 
          alt={Name}
          className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48" />
      </div>

      <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
        <div className="relative pr-9  sm:pr-0">
          <div className='w-10/12'>
            <div className="flex justify-between">
              <h1 className="">
                <span className="font-medium text-xl text-gray-700 hover:text-gray-800">
                  {Name}
                </span>
              </h1>
            </div>
            {isMobile  && (
              <div className='grid grid-cols-2 gap-12 divide-x mt-5'>

                <a href={link} target="_blank" rel="noreferrer" className="mt-4 flex space-x-2 text-sm text-gray-700 hover:text-underline hover:text-teal-500">
                  <span>En savoir plus</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>

                </a>
                <a href={product_price_page} target="_blank" rel="noreferrer" className="mt-4 flex space-x-2 text-sm text-gray-700 hover:text-blue-500">
                  <span>Page produit</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                  </svg>

                </a>
              </div>
            )}
            {Archived ? (
              <div className="mt-1 flex text-sm">
                <p className="text-gray-500 text-xs">
                  <span>Désolé, ce n'est plus necessaire de chercher cet article </span>
                </p>
              </div>
            ) : (
              <>
                <div className="mt-1 flex text-sm">
                  <p className={`text-gray-500 ${description?.length > 500 ? 'text-xs h-60' : 'text-sm'}`}>
                    <DetailsDescription description={description?.length > 0 ? description.slice(0,505) : ''} className="h-full w-full" />
                  </p>
                </div>
                <p className="mt-1 text-sm text-left font-medium text-gray-900">
                  {product_price} €
                </p>
              </>
            )}
          </div>

        </div>

        {!isMobile  && (
          <div className='grid grid-cols-2 gap-12 divide-x mt-5'>

            <a href={link} target="_blank" rel="noreferrer" className="mt-4 flex space-x-2 text-sm text-gray-700 hover:text-underline hover:text-teal-500">
              <span>En savoir plus</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
              </svg>

            </a>
            <a href={product_price_page} target="_blank" rel="noreferrer" className="mt-4 flex space-x-2 text-sm text-gray-700 hover:text-blue-500">
              <span>Page produit</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
              </svg>

            </a>
          </div>
        )}
      </div>
    </li>
  )
}

export default GiftRowItem;
import { useEffect, useState } from "react"
import { config, currentUserApi, users } from "../utils"
import axios from "axios"
import { useAuth } from "../AuthContext"
import Button from "./Button"

type FormattedRecord = {
  name: string
  pots: Object
}

const ProfileScreen = () => {
  const {user, logout} = useAuth()
  const [currentUser, setCurrentUser] = useState<string>()
  const [fetchedParticipations, setFetchedParticipations] = useState(false)
  const [participations, setParticipations] = useState<Object>({})

  useEffect(() => {
    if(!currentUser && user && user !== undefined) {
      const {username} = user
      if(username) {
        setCurrentUser(username)
      }
    }
  }, [currentUser, user])

  useEffect(() => {
    function fetchParticipations(){
      if (currentUser) {
        users().forEach(async (tUser: string) => {
          const resp = await axios.get(currentUserApi(tUser), {headers: config.headers});
          const formattedRecords = resp.data.records.map(({fields: {pots, Name}}) =>
          {
            return {name: Name, pots: JSON.parse(pots)}
          }); 
          const matches = formattedRecords.filter(({pots: i}) => Object.keys(i).includes(currentUser))
          const sum = matches.reduce((prev: number, val: FormattedRecord) => {
              return prev + val.pots[currentUser]
            }, 0)
          var newParticipations = participations
          newParticipations[tUser] = [sum, matches.map(m => m.name)]
          setParticipations(newParticipations)
        })
        setFetchedParticipations(true)

      }
    }
    if(currentUser && !fetchedParticipations) {
      fetchParticipations()
    }
  }, [currentUser, fetchedParticipations, participations])
  
  return (
    <div className="w-2/3 text-left mx-auto">
      
      <div>
        <p className="">
          Profil
        </p>
        {user && (<>
          <p className="">
            Bonjour {user?.username}
          </p>
          <Button className="flex-none bg-red-500 hover:bg-red-700" onClick={() => logout()}>
            Me déconnecter
          </Button>
        </>)}

      </div>
      {user && (

        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Tes participations
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Voici le listing de tes participations. Si rien ne s'affiche reclique sur "Profil"
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          {fetchedParticipations && currentUser && users().map((targetUser: string) => {
            if(targetUser === currentUser) { return <div key={targetUser}> </div>}
            return (
              <dl key={`${targetUser}-key`} className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 py-3">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {targetUser}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {participations[targetUser] ? participations[targetUser][0] : 0} €
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <ul className="list-disc">
                      {participations[targetUser] && participations[targetUser][1].map(giftName => (
                        <li key={giftName}>
                          {giftName}
                        </li>
                      ))}
                    </ul>
                  </dd>
                </div>
                <hr/>
              </dl>
            )
          })}
          {fetchedParticipations && currentUser &&  (
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 py-3">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Somme totale
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {participations && Object.values(participations).reduce((prev, i) => prev + (i ? i[0] : 0), 0)} €
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                </dd>
              </div>
            </dl>
          )}
          </div>
        </div>
      )}


    </div>
  )
}

export default ProfileScreen
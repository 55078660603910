import { useForm } from "react-hook-form";
import { ActionTypes, config, currentUserApi, NewProps } from "../utils";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "./ConfirmDialog";
import DetailsDescription from "./DetailsDescription";
import MDEditor from '@uiw/react-md-editor';
import { useAuth } from "../AuthContext";


const schema = yup.object({
  product_price: yup.number().positive().integer(),
});

const GiftFormComponent = ({method, gift}: NewProps) => {
  const {user} = useAuth()
  const [currentUser, setCurrentUser] = useState<string>()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const history = useHistory();
  const [giftImg, setGiftImg] = useState<string>()
  const [currentDescription, setCurrentDescription] = useState<string>()
   const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if(!currentUser && user && user !== undefined) {
      const {username} = user
      if(username) {
        setCurrentUser(username)
      }
    }
  }, [currentUser, user])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if(name === "product_image") {
        setGiftImg(value[name])
      } else if(name === "description"){
        setCurrentDescription(value[name])
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if(gift !== undefined) {
      setGiftImg(gift.fields.product_image)
      setCurrentDescription(gift.fields.description)
    }
  }, [gift])

  //  console.log(watch("product_image"))

  const onSubmit = (giftAttributes: any) => {
    const fields = {...giftAttributes, description: currentDescription}
    const records = method === ActionTypes.PATCH && gift ? [{id: gift.id, fields: fields}] : [{fields: fields}]
    const body = {records: records}

    let newConfig = {...config, method: method, body: JSON.stringify(body)}
    if(currentUser) {
      fetch(currentUserApi(currentUser), newConfig)
      .then(res => res.json())
      .then(res => {
        history.push("/")
      })
      .catch(error => {
        console.error(error)
      })
    }
  }

  const deleteGift = (giftId: string) => {
    let newConfig = {...config, method: 'DELETE'}
    if(currentUser) {
      let deleteUrl = `${currentUserApi(currentUser)}?records[]=${giftId}`
  
      fetch(deleteUrl, newConfig)
        .then(res => res.json())
        .then(res => {
          history.push("/")
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  return (<>
    <div className="text-left">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1 pl-4">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
                Ajoute un souhait de cadeau
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              Informez le reste du groupe de votre nouvelle idée cadeau
            </p>
            {currentUser && (
              <p className="mt-1 text-sm text-indigo-400">
                Ce cadeau sera ajouté à la liste <span className="text-indigo-600">'{currentUser}'</span>
              </p>
            )}
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="shadow sm:rounded-md sm:overflow-hidden w-2/3">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                      Archiver le produit ?
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="checkbox"
                        id="gift-archived"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block rounded-md"
                        placeholder="Nom du produit"
                        checked={gift?.fields.Archived}
                        {...register("Archived", { required: true })}
                      />
                    </div>
                    {errors.Name && <span>Ce champs est requis</span>}
                  </div>
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                      Nom
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        id="gift-name"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                        placeholder="Nom du produit"
                        defaultValue={gift?.fields.Name}
                        {...register("Name", { required: true })}
                      />
                    </div>
                    {errors.Name && <span>Ce champs est requis</span>}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                      Page prix produit
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        http://
                      </span>
                      <input
                        type="url"
                        id="gift-product_price_page"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="ex: https://www.ledenicheur.fr"
                        defaultValue={gift?.fields.product_price_page}
                        {...register("product_price_page", { required: true })}
                      />
                    </div>
                    {errors.product_price_page && <span>Ce champs est requis</span>}
                  </div>
                </div>
                
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                      Prix produit
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="number"
                        id="gift-product_price"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                        placeholder="50 €"
                        defaultValue={gift?.fields.product_price}
                        {...register("product_price", {min: 0})}
                      />
                      <span className="inline-flex items-center px-3 rounded-r-md border border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        €
                      </span>
                    </div>
                    {errors.product_price && <span>Ce champs est requis, mets une valeur aléatoire si tu ne sais pas</span>}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Photo</label>
                  <div className="mt-1 flex items-center">
                    <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                      {giftImg ? (
                        <img src={giftImg} alt="new gift sample img" className="h-full w-full" />
                      ) : (
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      )}
                    </span>
                    <input
                        type="url"
                        id="company-website"
                        className="ml-4 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                        placeholder="URL de l'image"
                        defaultValue={gift?.fields.product_image}
                        {...register("product_image")}
                      />
                    </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Si vous n'avez pas d'image, trouvez en une sur <a href="https://www.stocklib.fr" rel="noreferrer" target="_blank" className="underline text-indigo-400">stocklib.fr</a><br/>
                    Après avoir fait votre recherche, le clic droit sera votre ami pour récupérer l'url.
                  </p>
                </div>

                <div>
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div className="mt-1">
                    <MDEditor value={currentDescription} onChange={setCurrentDescription} preview='edit' extraCommands={[]} />
                    {/* <textarea
                      id="gift-description"
                      rows={3}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Exliquez pourquoi ce cadeau serai super pour vous"
                      defaultValue={gift?.fields.description}
                      {...register("description")}
                    /> */}
                    {currentDescription && (
                      <div className="rounded border mt-2 p-2 bg-gray-100" >
                        <em>Aperçu du rendu</em>
                        <hr/>
                        <DetailsDescription description={currentDescription} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <input type="hidden" defaultValue={"{}"} {...register("pots")} />
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sauvegarder
                </button>
              </div>
            </div>
          </form>
          {gift && (
            <button 
              type="button" 
              className="inline-flex items-center px-4 py-2 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={() => setShowDeleteDialog(true)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="-ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
              Supprimer
            </button>
          )}
        </div>
      </div>
      {gift && (
        <ConfirmDialog 
          title="Supprimer ce cadeau ?"
          open={showDeleteDialog}
          onClose={() => {setShowDeleteDialog(false)}}
          onConfirm={() => deleteGift(gift.id)}
        >
          Es-tu sûr de vouloir supprimer ce cadeau?
        </ConfirmDialog>
      )}
    </div>

    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className="border-t border-gray-200" />
      </div>
    </div>
    </>
  )
}

export default GiftFormComponent

import { Route, Switch } from 'react-router'
import { ActionTypes } from '../utils'
import DetailsScreen from './DetailsScreen'
import FooterComponent from './FooterComponent'
import GiftFormComponent from './GiftFormComponent'
import HeaderComponent from './HeaderComponent'
import HomeScreen from './HomeScreen'
import NameModal from './NameModal'
import ProfileScreen from './ProfileScreen'
import ProtectedRoute from './ProtectedRoute'
import {Helmet} from "react-helmet";
import NewDetailsScreen from './NewDetailsScreen'

const MainContainer = () => {
  return (
    <div className="App">
      <Helmet>
        <title>Liste de Cadeaux | tiboreno.dev </title>
        <meta property="og:image" content="https://ia.media-imdb.com/images/rock.jpg" />
        <meta property="author" content="@tuxtux59" />
      </Helmet>
      <NameModal/>
      <HeaderComponent />
      <Switch>
        <div id="body">
          <ProtectedRoute path="/new" component={() => <GiftFormComponent method={ActionTypes.POST} />} />
          <ProtectedRoute path="/profile" component={ProfileScreen}/>
          <ProtectedRoute path="/:currentUser/:giftId" component={NewDetailsScreen}/>
          <Route exact path="/">
            <HomeScreen />
          </Route>
        </div>
      </Switch>
      <FooterComponent />
    </div>
  )
}

export default MainContainer
import {ReactNode, createContext, useContext, useReducer} from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

type User = {
  username: string;
}

interface AuthContextState {
  user: User | null;
  login: (user: User) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
}

const initialState: AuthContextState = {
    user: cookies.get('giftibo-authent'),
    login: () => console.warn('login function not implemented'),
    logout: () => console.warn('logout function not implemented'),
    isAuthenticated: () => false,
  };

const AuthContext = createContext<AuthContextState>(initialState);
export const useAuth = () => useContext(AuthContext);

interface AuthReducerAction {
  type: 'LOGIN' | 'LOGOUT';
  payload: any;
}

const reducer = (state: AuthContextState, action: AuthReducerAction): AuthContextState => {
  switch(action.type) {
    case 'LOGIN':
      cookies.set('giftibo-authent', action.payload)
      return {...state, user: action.payload}
      case 'LOGOUT':
      cookies.remove('giftibo-authent')
      return {...state, user: null}
    default:
      return state;
  }
}

interface Props {
  children: ReactNode
}


export const AuthProvider = ({children}: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const login = (userData: User) => dispatch({type: 'LOGIN', payload: userData})
  const logout = () => dispatch({type: 'LOGOUT', payload: null})
  const isAuthenticated = () => state.user !== undefined && state.user !== null


  return (
    <AuthContext.Provider value={{...state, login, logout, isAuthenticated}}>
      {children}
    </AuthContext.Provider>
  );
};
import React, { useCallback, useEffect } from 'react'
import { useState } from "react"
import { btnGroupClass, config, currentUserApi, Disclaimer, fetchDisclaimers, Gift, sortGifts, SortType, users } from '../utils'
import EmptyResultsComponent from './EmptyResultsComponent'
import GiftItem from "./GiftItem"
import LoadingComponent from './LoadingComponent'
import SortGiftsComponent from './SortGiftsComponent'
import { Link } from 'react-router-dom'
import GiftRowItem from './GiftRowItem'
import { isMobile } from 'react-device-detect'
import { useAuth } from '../AuthContext'

const HomeScreen = () => {
  const {user, isAuthenticated} = useAuth()
  const [refreshing, setRefreshing] = useState(false)
  const [fetchedGifts, setFetchedGifts] = useState(false)
  const [display, setDisplay] = useState<'cards' | 'grid-list'>('grid-list')
  const [gifts, setGifts] = useState<Gift[]>([])
  const [archivedgifts, setArchivedGifts] = useState<Gift[]>([])
  const [sessionUser, setSessionUser] = useState<string>()
  const [currentUser, setCurentUser] = useState<string>()
  const [currentSort, setCurrentSort] = useState<SortType>(SortType.None)
  const [disclaimers, setDisclaimers] = useState<Disclaimer[]>([]);
  const currentUsers = users()

  useEffect(() => {
    if(user && user !== undefined) {
      const {username} = user
      if(username) {
        setCurentUser(username)
        setSessionUser(username)
      }
    }
  }, [user])

  useEffect(() => {
    fetchDisclaimers()
      .then(res => res.json())
      .then(res => {
        setDisclaimers(res.records)
      })
      .catch(error => {
        console.error(error);
      })
      setDisplay(isMobile ? 'grid-list': 'cards')
  }, [user])
  
  const fetchGifts = useCallback(() => {
    setRefreshing(true)
    currentUser && fetch(currentUserApi(currentUser), config)
      .then(res => res.json())
      .then(res => {
        setRefreshing(false)
        setFetchedGifts(true)
        setGifts(res.records.filter((gift: Gift) => !gift.fields.Archived))
        setArchivedGifts(res.records.filter((gift: Gift) => gift.fields.Archived))
      })
      .catch(error => {
        setFetchedGifts(true)
        setRefreshing(false)
      })
  }, [currentUser])


  useEffect(() => {
    console.log("not fetchedGifs")
    fetchGifts()
  }, [fetchGifts, fetchedGifts, currentUser])

  if(isAuthenticated()) {
    return (
      <div>
        <h3>Authentifié</h3>
        {currentUsers.length > 0 ? (
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            {currentUser && currentUsers?.map((user, index) => {
              const textColor = user === currentUser ? 'text-indigo-700' : 'text-gray-700'
  
              return (
                <button
                  type="button"
                  key={user}
                  onClick={() => setCurentUser(user)}
                  className={`${btnGroupClass(index, currentUsers.length)} relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${textColor} hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                >
                  {user}
                </button>
              )
              }
            )}
          </span>
        ): (<span>No users</span>)}
        {gifts && gifts.length > 0 && (
          <>
            <SortGiftsComponent currentSort={currentSort} setCurrentSort={setCurrentSort} />
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                onClick={() => setDisplay('grid-list')}
                className={`relative inline-flex items-center rounded-l-md bg-white px-2 py-2 ${display === 'grid-list' ? 'text-blue-400' : 'text-gray-400'} ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}>
                <span className="sr-only">Grid-list</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>

              </button>
              <button
                type="button"
                onClick={() => setDisplay('cards')}
                className={`relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 ${display === 'cards' ? 'text-blue-400' : 'text-gray-400'} ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}>
                <span className="sr-only">Cards</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                </svg>

              </button>
            </span>

          </>
        )}
        {disclaimers && (
          <>
            <hr/>
            <div className="container  mx-auto  my-2 bg-amber-100 rounded-lg py-5 px-6 mb-4 text-base text-amber-700" role="alert">
              <h4 className="text-2xl font-medium leading-tight mb-2">
                Mises en garde
              </h4>
              <em className="mt-4 mb-0">
                L'utilisateur courant à des remarques pour éviter tout faux pas sur les propositions ci-dessous
              </em>
              {disclaimers.filter(({fields: {Titre, User}}) => Titre && User === currentUser).map(({fields: {Titre, Details}}) => (
                <>
                  <hr/>
                  <h5 className="text-xl front-medium my-2">{Titre}</h5>
                  <p>{Details}</p>
                </>
              ))}
            </div>
            <hr/>
          </>
        )}
        <div className="mt-8">
          {!refreshing && currentUser && sessionUser ? (
            <section className="text-gray-600 body-font">
              <div className="container px-5 pb-24 mx-auto">
                <div className={display === 'cards' ? "flex flex-wrap -m-4": 'grid grid-cols-1 xl:grid xl:grid-cols-2 gap-5'}>
                  {gifts && gifts.length > 0 ? (
                    <>
                      {
                        sortGifts(gifts, currentSort).map((gift: Gift) => {
                          const { id } = gift

                          return display === 'grid-list' ? <GiftRowItem key={id} gift={gift} currentUser={currentUser} sessionUser={sessionUser} /> : <GiftItem key={id} currentUser={currentUser} sessionUser={sessionUser} gift={gift} />
                        })
                      }
                      {sessionUser && currentUser === sessionUser && (
                        <div className="p-4 sm:w-1/2 md:w-1/3 lg:w-1/4 text-center">
                          <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <Link to="/new">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-32 w-32 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                              <div className="p-6">
                                Ajouter un autre souhait
                              </div>
                            </Link>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <EmptyResultsComponent sessionUser={sessionUser} user={currentUser} />
                  )}
                  
                </div>
                {archivedgifts && archivedgifts.length > 0 && (
                  <>
                    <hr style={{margin: "2em"}}/>
                    <h2 className='text-5xl mt-3 mb-6'>Ils ne sont plus d'actualité !</h2>
                    <div className="flex flex-wrap -m-4">
                      {
                        archivedgifts.map((gift: Gift) => {
                          const { id } = gift
        
                          return(
                              <GiftItem key={id} currentUser={currentUser} sessionUser={sessionUser} gift={gift} />
                          )
                        })
                      }
                    </div>
                  </>
                )}
              </div>
            </section>
          ) : (
            <LoadingComponent />
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <p>Vous n'êtes pas identifié</p>
      </div>
    )
  }

}

export default HomeScreen
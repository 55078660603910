import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { FilterIcon } from '@heroicons/react/solid'
import { SortProps, SortType } from '../utils'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const SortGiftsComponent = ({currentSort, setCurrentSort}: SortProps) => {

    return (
        <Menu as="div" className="relative inline-block text-left mx-2 py-auto">
            <div>
                <Menu.Button className="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-white">
                    <span className="sr-only">Open options</span>
                    <FilterIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items style={{zIndex: 1001}} className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    <Menu.Item>
                        {({ active }) => (
                            <span
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => setCurrentSort(SortType.Price)}
                            >
                                Trier par prix croissant
                            </span>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <span
                                onClick={() => setCurrentSort(SortType.Name)}
                                className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Trier par nom
                            </span>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <span
                                onClick={() => setCurrentSort(SortType.Participant)}
                                className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Trier par participant décroissant
                            </span>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <span
                                onClick={() => setCurrentSort(SortType.Founded)}
                                className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block w-full text-left px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Trier par pourcentage récolté
                            </span>
                        )}
                    </Menu.Item>
                </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default SortGiftsComponent

import React, { useCallback, useEffect, useState } from 'react';
import { ActionTypes, DEFAULT_IMG, DetailsActionTypes, DetailsScreenProps, Gift, config, currentUserApi } from '../utils';
import { useHistory, useParams } from 'react-router-dom';
import GiftFormComponent from './GiftFormComponent';
import LoadingComponent from './LoadingComponent';
import DetailsDescription from './DetailsDescription';
import {Helmet} from "react-helmet";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAuth } from '../AuthContext';

const schema = yup.object({
  product_price: yup.number().positive().integer(),
});

const NewDetailsScreen = () => {
  const {user} = useAuth();
  const history = useHistory()
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const [action, setAction] = useState<DetailsActionTypes>(DetailsActionTypes.Show)
  const [refreshing, setRefreshing] = useState(false)
  const [fetchedGift, setFetchedGift] = useState(false)
  const [sessionUser, setSessionUser] = useState<string>()
  const [gift, setGift] = useState<Gift>()
  const {currentUser, giftId} = useParams() as DetailsScreenProps

  const onSubmit = ({potValue}) => {
    if(JSON.parse(pots) !== undefined && sessionUser) {
      var newPots = JSON.parse(pots)
      newPots[`${sessionUser}`] = parseInt(potValue)
      const giftParams = {...gift, createdTime: undefined,fields: {pots: JSON.stringify(newPots)}}

      const body = {records: [giftParams]}
      let newConfig = {...config, method: ActionTypes.PATCH, body: JSON.stringify(body)}
      fetch(currentUserApi(currentUser), newConfig)
      .then(res => res.json())
      .then(res => {
        history.go(0)
      })
      .catch(error => {
        console.error(error)
      })
    }
  }

  useEffect(() => {
    if(user && user !== undefined) {
      const {username} = user
      if(username) {
        setSessionUser(username)
      }
    }
  }, [user])

  const fetchGift = useCallback(() => {
    setRefreshing(true)
    
    fetch(currentUserApi(currentUser), config)
      .then(res => res.json())
      .then(res => {
        setRefreshing(false)
        setFetchedGift(true)
        setGift(res.records.find((g: Gift) => g.id === giftId))
      })
      .catch(error => {
        setFetchedGift(true)
        setRefreshing(false)
      })
  }, [currentUser, giftId])

  useEffect(() => {
    if(!fetchedGift) {
      console.warn("not fetchedGifs")
      fetchGift()
    }
  }, [fetchGift, fetchedGift])

  useEffect(() => {
    console.log('currentUser', currentUser)
  }, [currentUser])
  
  useEffect(() => {
    console.log('sessionUser', sessionUser)
  }, [sessionUser])

  if(action === DetailsActionTypes.Edit) {
    return <GiftFormComponent gift={gift} method={ActionTypes.PATCH} />
  }

  if(refreshing || !gift) {
    return <LoadingComponent/>
  }

  const {fields: {Name, description, product_image, pots, product_price, product_price_page}} = gift;
  
  const parsedPots = JSON.parse(pots)
  const participant_count = Object.keys(parsedPots).length
  const foundedValue = Object.values(parsedPots).reduce((r, n) => (r as number) + (n as number), 0)
  const progress = participant_count > 0 ? (foundedValue as number * 100) / product_price : 0
  const {location: {pathname}} = history;
  const maxParticipation = foundedValue ? product_price - (foundedValue as number) : product_image

  return (
    <div className="bg-white">
      <Helmet>
        <title> {pathname.split('/')[1]} | {Name} </title>
        <meta property="og:image" content={product_image || DEFAULT_IMG} />
        <meta property="author" content="@tuxtux59" />
      </Helmet>
      <main className="mx-auto mt-8 max-w-2xl px-4 pb-16 sm:px-6 sm:pb-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-2">
          <div className="lg:col-span-5 lg:col-start-8">
            <div className="flex justify-between">
              <h1 className="text-xl font-medium text-gray-900">
                {Name}
              </h1>
              <p className="text-xl font-medium text-lime-600">
                {product_price} €
              </p>
            </div>
            <br/>
            {sessionUser && currentUser !== sessionUser && (
              <div className="mt-4 mb-8">
                <h2 className="sr-only">Reviews</h2>
                  <div className="flex items-center">
                    <p className="text-sm text-gray-700">
                      {Math.ceil(progress / 10) * 10}%
                      <span className="sr-only"> out of 5 stars</span>
                    </p>
                    <div className="ml-1 flex items-center">
                      {[...Array(5)].map((_, i) => {
                        const checkedStars = Math.floor(progress / 20)
                        return (
                        <svg key={`progress-${i}`} className={`${(progress > 0 && i <= checkedStars) ? 'text-yellow-400' : 'text-gray-200' } h-5 w-5 flex-shrink-0`} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                        </svg>
                        )
                      })}
                    </div>
                    <div aria-hidden="true" className="ml-4 text-sm text-gray-300">·</div>
                    <div className="ml-4 flex">
                      <span className="text-sm font-medium text-indigo-600 hover:text-indigo-500">Progression</span>
                    </div>
                  </div>
              </div>
            )}
          </div>

          <div className="lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
            <div className="">
              <img
                src={product_image || DEFAULT_IMG}
                alt={Name}
                className="lg:col-span-2 lg:row-span-2 rounded-lg max-w-xl"
              />
            </div>
          </div>

          <div className="lg:col-span-5">

            {description !== undefined ? (

              <div className="my-10">
                <h2 className="text-sm font-medium text-gray-900">Description</h2>

                <DetailsDescription className="prose prose-sm mt-3 text-gray-500" description={description || '-'} />
              </div>
            ) : (
              <p className='my-3 pb-5'>Pas de description</p>
            )}

              <a
                href={product_price_page}
                target='_blank'
                rel="noreferrer"
                className="inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
              En savoir plus
            </a>
            <div className='border-top mt-5'>
              {sessionUser && currentUser !== sessionUser ? (
                <>
                  <div className='mb-3'>
                    <span className="text-gray-600 ml-3">
                      {`${participant_count} participant${participant_count > 1 ? 's' : ''}`}
                    </span>
                    {participant_count > 0 && (
                      <div className='grid grid-cols-3'>
                        {Object.entries(parsedPots).map(([participant, montant], _) => (
                          <div 
                            key={`${participant}-${montant}`}
                            className="h-8 px-2 mr-2 text-gray-400 border border-indigo-300 transition-colors duration-150 rounded w-fit focus:shadow-outline hover:bg-indigo-100"
                          >
                            <span className="mx-2 mt-1">{participant}</span>
                            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-indigo-400 rounded-full">
                              {`${montant} €`}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="w-full" style={{display: "contents"}}>
                    <div className="space-y-12 text-left">
                      <div className="border-b border-gray-900/10 pb-12">
                        <div className="relative">
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300"></div>
                          </div>
                          <div className="relative flex justify-center">
                            <span className="bg-white px-2 text-sm text-gray-500">Participer</span>
                          </div>
                        </div>

                        <p className="mt-1 text-sm leading-6 text-gray-600">Donne le montant que tu souhaite mettre</p>

                        <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Montant</label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">€</span>
                                <input
                                  type="number"
                                  id="pot-value"
                                  min={1}
                                  max={maxParticipation}
                                  required={true}
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder=" 5"
                                  {...register("potValue", {min: 1, max:  maxParticipation, required: true})}
                                />
                                {errors.potValue && <span>Mauvise entrée</span>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-end">
                          <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            {Object.keys(parsedPots).indexOf(sessionUser) === -1 ? 'Je participe' : 'Je change'}
                          </button>
                        </div>
                      </div>

                    </div>
                  </form>
                </>
              ): (
                <p className="border-l ml-2 pl-2">
                  Tu n'auras pas accès à la progression &#128540;
                  <span>Mais&nbsp;
                    <strong onClick={() => setAction(DetailsActionTypes.Edit)} className="text-indigo-900 cursor-pointer">clique ici pour modifier</strong> 
                    </span>
                </p>
              )}
            </div>

          </div>

        </div>
      </main>
    </div>
  )
}

export default NewDetailsScreen;
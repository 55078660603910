import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom'
import MainContainer from './components/MainContainer';
import { AuthProvider } from './AuthContext';

function App() {
  
  return (
    <AuthProvider>
      <Router>
        <MainContainer />
      </Router>
    </AuthProvider>
  );
}

export default App;

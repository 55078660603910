import React from 'react'
import { Route } from "react-router-dom"
// import { useAuth } from '../AuthContext';

// function signInWithDomain(REACT_APP_CRYPTR_TENANT_DOMAIN: string | undefined): React.ReactNode {
//     throw new Error('Function not implemented.');
// }

const ProtectedRoute = ({ component: Component, ...rest }) => {
    // const {isAuthenticated} = useAuth()
    return <Route {...rest} render={(props) => (<Component {...props} />)} />

        // return <div>{isAuthenticated}</div>
        // return (
        //     <Route {...rest} render={(props) => (
        //         isAuthenticated() ? (
        //              <Component {...props} />
        //          ) : signInWithDomain(process.env.REACT_APP_CRYPTR_TENANT_DOMAIN)
        //          )
        //     } />
        // )
    // }

}
 
export default ProtectedRoute